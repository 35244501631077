@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

.imglogo {
  width: 50px;
  margin-right: 10px;
  margin-left: 20px;
}

.partner-img {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.baru {
  border: 2px solid #e2dddd;
  background-color: #fff;
}

.imglogo2 {
  width: 190px;
}

/* 768 */
@media (max-width: 768px) {
  .partner-img {
    display: grid;
    grid-template-columns: 0.4fr 0.4fr 0.4fr;
    gap: 20px;
  }

  .imglogo2 {
    width: 100px;
  }
}

/* 1068 */
@media (max-width: 1068px) {
  .partner-img {
    display: grid;
    grid-template-columns: 0.4fr 0.4fr 0.4f 0.4fr;
    gap: 20px;
  }

  .imglogo2 {
    width: 100px;
  }
}
