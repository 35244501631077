.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 950px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

/* Button */
.indicator {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border: 1px #666 solid;
  margin: 0;
}

.indicator.active {
  color: #fff;
  background: #666;
}
